.fp-comp-button-action {
  display: flex;
  align-items: center;
  gap: 8px;
  width: fit-content;
  height: 48px;
  min-height: 48px;
  padding: 0 24px;
  border-radius: 16px 0;
  background-color: var(--fp-color-blue-degree-95);
  --fp-comp-button-action-icon-fill: var(--fp-color-turquoise-main);
  margin-top: 8px;
  cursor: pointer;
  
  &.full-width {
    width: 100%;
  }

  &.special {
    --fp-comp-button-action-icon-fill: var(--fp-color-white);
    background-color: var(--fp-color-turquoise-main);
  }

  >.icon {
    display: flex;
    align-items: center;
    height: 100%;
  }

  >.title {
    display: flex;
    align-items: center;
    height: 100%;
  }
}