.header .header-links-holder {
    display: flex;
    align-items: center;
}

.header .header-links-holder .cross-button {
    display: none;
}

.header .menu-button {
    display: none;
}

@media (max-width: 768px) {
    .header .header-links-holder {
        position: fixed;
        inset: 0;
        width: 100%;
        height: 100%;
        background-color: #fff;
        flex-direction: column;
        align-items: baseline;
        gap: 14px;
        z-index: 100;
        padding-top: 70px;
        padding-left: 20px;
        padding-right: 20px;
        transform: translateX(100%);
        transition: all .4s ease;
    }

    .header .header-links-holder.active {
        transform: translateX(0%);
        transition: all .4s ease;
    }

    .header .header-links-holder .cross-button {
        display: flex;
        position: absolute;
        top: 8px;
        right: 20px;
    }

    .header .header-links-holder .cross-button .icon {
        width: 14px;
        height: 14px;
    }

    .header .header-links-holder .cross-button img {
        width: 100%;
        height: 100%;
    }

    .header .menu-button {
        display: flex;
    }

    .header .fp-comp-separator.orientation-vertical {
        display: none;
    }
}