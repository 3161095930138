.fp-comp-reviews {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 16px;
  width: 100%;
  --fp-comp-reviews-icon-fill: var(--fp-color-white);
  
  &.variation-mobile {
    grid-template-columns: 1fr;
  }
  
  >.figure {
    width: 100%;
    height: 368px;
    border: 1px solid var(--fp-color-blue-main);
    border-radius: 16px 0;
    overflow: hidden;
    padding: 0 0 7px 7px;
    background-color: var(--fp-color-white);

    .container {
      width: 100%;
      height: 100%;
      background-color: var(--fp-color-blue-degree-95);
      border-radius: 16px 0;
      overflow: hidden;

      >.image {
        width: 100%;
        height: calc(100% - 48px);
        position: relative;

        >img {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        &::after {
          content: "";
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background-color: var(--fp-color-blue-main);
          mix-blend-mode: color;
        }
      }

      >.rating {
        display: flex;
        height: 48px;
        width: 100%;
        gap: 16px;
        padding: 0 16px;
        align-items: center;

        >.stars {
          display: flex;
          gap: 8px;
        }
      }
    }

  }

  >.caption {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    border: 1px solid var(--fp-color-blue-main);
    border-radius: 16px 0;
    overflow: hidden;
    background-color: var(--fp-color-blue-degree-95);
    margin-bottom: 48px;

    >.body {
      padding: 32px;
      margin-bottom: auto;
      height: calc(100% - 48px);

      >.quote {
        padding: 0 32px;
      }
    }

    >.bar {
      width: 100%;
      display: flex;
      height: 48px;
      background-color: var(--fp-color-white);
      border-radius: 16px 0 0 0;

      >.info {
        display: flex;
        width: 100%;
        height: 100%;
        padding: 0 24px;
        gap: 16px;
        align-items: center;
        overflow: hidden;

        >.author {
          white-space: nowrap;
        }

        >.role {
          display: flex;
          align-items: center;
          height: 100%;
          gap: 8px;
          white-space: nowrap;
          // overflow: hidden;
        }
      }

      >.arrows {
        align-items: center;
        display: flex;

        >.arrow {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 48px;
          width: 48px;
          height: 100%;
          cursor: pointer;
        }
      }
    }
  }

}