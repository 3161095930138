.fp-comp-hero-card {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border: 1px solid var(--fp-color-blue-main);
  border-radius: 16px 0;
  overflow: hidden;

  >.body {
    display: flex;
    flex-direction: column;
    padding: 32px;
    padding-right: 64px;
    gap: 16px;
  }

  >.actions {
    display: flex;
    margin-top: auto;

    >.action {
      display: flex;
      gap: 8px;
      align-items: center;
      height: 48px;
      width: 50%;
      padding: 0 24px;
      cursor: pointer;

      >.icon {
        display: flex;
        height: 100%;
        align-items: center;
        justify-content: center;
      }

      &.action-primary {
        border-radius: 16px 0 0 0;
        background-color: var(--fp-color-turquoise-main);

        >.icon {
          --fp-comp-hero-card-icon-fill: var(--fp-color-white)
        }
      }

      &.action-secondary {
        background-color: var(--fp-color-white);

        >.icon {
          --fp-comp-hero-card-icon-fill: var(--fp-color-turquoise-main)
        }
      }
    }
  }
}