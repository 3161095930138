/* Conteneur principal */
.marketing-banner-container {
    background: #e6eeff; /* Couleur de fond */
    padding: 12px 18px;
    border-radius: 12px 0;
    margin-bottom: 8px;
    overflow: hidden;
}

/* Wrapper principal */
.marketing-banner-wrapper {
    max-width: 1200px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 16px;
    flex-wrap: wrap;
}

/* Détails pour Desktop */
.marketing-banner-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;
    flex-wrap: wrap;
    font-size: 12px;
}

.marketing-banner-detail-item {
    display: flex;
    align-items: center;
    gap: 6px;
    color: #374151;
    font-size: 12px;
}

.marketing-banner-detail-item.second-line {
    margin-top: 4px;
}

/* Call to Action (Desktop) */
.call-to-action-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-grow: 1;
}

.call-to-action-link {
    background-color: #99ffde; /* Couleur du CTA */
    color: #1f2937;
    border: none;
    padding: 8px 16px;
    display: inline-flex;
    align-items: center;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease;
    border-radius: 12px 0;
    text-decoration: none;
    white-space: nowrap;
    font-size: 14px; /* Taille de la police adaptée pour visibilité */
    gap: 8px; /* Espacement entre l'icône et le texte */
}

.call-to-action-link:hover {
    background-color: #88e6cc; /* Surlignage pour indiquer l'interaction */
}

/* Icône */
.marketing-banner-icon {
    width: 20px; /* Augmenter légèrement pour plus de visibilité */
    height: 20px;
}

/* Mobile-specific Styles */
.marketing-banner-wrapper.mobile {
    display: none;
}

.marketing-banner-wrapper.desktop-tablet {
    display: flex;
}

@media (max-width: 768px) {
    .marketing-banner-wrapper.desktop-tablet {
        display: none;
    }

    .marketing-banner-wrapper.mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;
        padding: 8px 0;
    }

    .marketing-banner-mobile-details {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        font-size: 14px;
    }

    .marketing-banner-button.mobile-button {
        font-size: 14px;
        padding: 6px 12px;
        margin-top: 4px;
        background-color: #99ffde;
        color: #1f2937;
        border-radius: 8px;
        text-decoration: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
        transition: all 0.2s ease;
    }

    .mobile-button:hover {
        background-color: #88e6cc;
    }

    .mobile-opening-hours {
        display: flex;
        align-items: center;
        gap: 6px;
        margin-top: 8px;
        color: #374151;
        font-size: 14px;
    }
}