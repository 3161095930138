* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  outline: none;
  border: none;
  border-radius: 0;
  
  font-family: "kanit";
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-decoration: none;
  list-style: none;

  background: transparent;
  color: var(--fp-color-blue-main);
}