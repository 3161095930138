.floatingLogo .logo-img {
    width: 512px;
    height: 512px;
    position: absolute;
    top: 6px;
    left: 0;
}

.floatingLogo .logo-img img {
    width: 100%;
    height: 100%;
}

@media (max-width: 768px) {
    .floatingLogo .logo-img {
        width: 290px;
        height: 290px;
    }
}