.sectionContactSteps {
    padding: 115px 0;
    position: relative;
}

.sectionContactSteps .contactSteps-inner {
    position: relative;
    z-index: 1;
}

.sectionContactSteps .contactSteps-inner .steps {
    display: flex;
    align-items: center;
    gap: 16px;
    padding: 16px 0;
}

.sectionContactSteps .contactSteps-inner .steps .steps-item {
    display: flex;
    align-items: center;
    border-radius: 16px 0;
    padding: 9px 19px;
    gap: 8px;
    border-width: 1px;
    border-style: solid;
}

.sectionContactSteps .contactSteps-inner .steps .steps-item:nth-child(1) {
    background-color: var(--fp-color-turquoise-main);
    border-color: transparent;
}

.sectionContactSteps .contactSteps-inner .steps .steps-item:nth-child(3) {
    background-color: #E5EEFF;
    border-color: transparent;
}

.sectionContactSteps .contactSteps-inner .steps .steps-item:nth-child(5) {
    background-color: transparent;
    border-color: #E5EEFF;
}

.sectionContactSteps .contactSteps-inner .steps .steps-item p {
    font-weight: 300;
}

.sectionContactSteps .contactSteps-inner .contactForm {
    padding-top: 74px;
}

.sectionContactSteps .contactSteps-inner .contactForm .form-top {
    margin-bottom: 48px;
}

.sectionContactSteps .contactSteps-inner .contactForm .form-top .form-subtitle {
    display: flex;
    align-items: center;
    gap: 8px;
}

.sectionContactSteps .contactSteps-inner .contactForm .form-top .form-subtitle p {
    font-weight: 300;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    row-gap: 8px;
    column-gap: 16px;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form .yesNo-item {
    border-radius: 16px 0;
    height: 48px;
    padding-left: 24px;
    border: 1px solid #e5eeff;
    display: flex;
    align-items: center;
    margin-top: 8px;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form .yesNo-item p {
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    width: 70%;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form .yesNo-item .buttons {
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    width: 30%;
    height: 48px;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form .yesNo-item .buttons button {
    font-size: 16px;
    font-weight: 300;
    line-height: 1;
    cursor: pointer;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form .yesNo-item .buttons button:nth-child(2) {
    border-radius: 0 0 16px 0;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-form .yesNo-item .buttons button.active {
    background-color: #E5EEFF;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-action-button{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 74px;
    gap: 20px;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-action-button button {
    display: flex;
    align-items: center;
    border-radius: 16px 0;
    padding: 9px 19px;
    gap: 8px;
    border-width: 1px;
    border-style: solid;
    cursor: pointer;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-action-button .prev-button{
    background-color: transparent;
    border-color: #E5EEFF;
}

.sectionContactSteps .contactSteps-inner .contactForm .contactForm-action-button .next-button{
    background-color: var(--fp-color-turquoise-main);
    border-color: transparent;
}

@media (max-width: 768px) {
    .sectionContactSteps .contactSteps-inner .contactForm .contactForm-form {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }

    .sectionContactSteps .contactSteps-inner .steps {
        gap: 0px;
    }
}