.legal-page {
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  margin: 10px 10vw;
  padding: 30px;
  border: 1px solid #ccc;
  border-radius: 12px;
  background-color: #f0f4ff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.legal-page .legal-title {
  color: #333;
  text-align: center;
  font-size: 2.5rem;
  margin-bottom: 20px;
  font-weight: bold;
}

.legal-page .legal-subtitle {
  color: #555;
  margin-top: 20px;
  margin-bottom: 10px;
  font-size: 1.8rem;
  border-bottom: 2px solid #0066cc;
  padding-bottom: 5px;
}

.legal-page .legal-info {
  color: #444;
  margin: 15px 0;
  font-size: 1rem;
  line-height: 1.8;
}

.legal-page a {
  color: #0066cc;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s;
}

.legal-page a:hover {
  text-decoration: underline;
  color: #004a99;
}

.legal-page .legal-list {
  padding-left: 20px;
  margin-bottom: 15px;
}

.legal-page .legal-list li {
  margin-bottom: 10px;
}

.legal-page h4.legal-subtitle {
  font-size: 1.4rem;
  margin-top: 15px;
}

.legal-page strong {
  font-weight: 600;
  color: #333;
}