.fp-comp-max-width {
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 0 20px;

  >.container {
    width: 100%;
    max-width: var(--fp-size-max-width);
  }
}