.assurances-cards {
  height: 100%;
  background-color: white;
  padding-bottom: 74px;

  &__title {
    text-align: center;
    font-size: 2rem;
    margin: 0 0 7rem;
    font-weight: 500;
  }

  &__grid {
    display: grid;
    grid-template-columns: repeat(3, minmax(0, 1fr));
    column-gap: 16px;
    row-gap: 263px;
  }
}

@media (max-width: 768px) {
  .assurances-cards {
    &__grid {
      grid-template-columns: repeat(1, minmax(0, 1fr));
      row-gap: 16px;
    }
  }
}