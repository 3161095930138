.sectionOurValues .holder {
    display: flex;
    border: 1px solid var(--fp-color-blue-main);
    border-radius: 16px 0;
    padding-right: 10px;
}

.sectionOurValues .holder .text {
    width: 65%;
    padding: 46px 36px;
}

.sectionOurValues .holder .values {
    width: 35%;
    background-color: var(--fp-color-blue-degree-95);
    padding: 46px 30px;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    gap: 10px;
}

.sectionOurValues .holder .values .item {
    display: flex;
    align-items: center;
    border: 1px solid var(--fp-color-blue-main);
    height: fit-content;
    justify-content: center;
    gap: 10px;
    padding: 8px;
}

.sectionOurValues .holder .values .item:nth-child(1),
.sectionOurValues .holder .values .item:nth-child(4),
.sectionOurValues .holder .values .item:nth-child(5) {
    background-color: #99ffdd;
}

.sectionOurValues .holder .values .item svg {
    width: 23px;
}

.sectionOurValues .holder .values .item svg:nth-child(1) {
    width: 19px;
}

.sectionOurValues .holder .values .item span {
    font-size: 18px;
}

@media (max-width: 940px) {
    .sectionOurValues .holder {
        flex-direction: column;
    }

    .sectionOurValues .holder .text {
        width: 100%;
    }

    .sectionOurValues .holder .values {
        width: 100%;
        row-gap: 20px;
    }
}

@media (max-width: 540px) {
    .sectionOurValues .holder .text {
        padding: 36px 26px;
    }

    .sectionOurValues .holder .values {
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 20px;
        padding: 36px 26px;
    }

    .sectionOurValues .holder .values .item:nth-child(1),
    .sectionOurValues .holder .values .item:nth-child(3),
    .sectionOurValues .holder .values .item:nth-child(5) {
        background-color: #99ffdd;
    }

    .sectionOurValues .holder .values .item:nth-child(2),
    .sectionOurValues .holder .values .item:nth-child(4),
    .sectionOurValues .holder .values .item:nth-child(6) {
        background-color: transparent;
    }
}