.fp-comp-nav-link {
  display: flex;
  gap: 8px;
  height: 40px;
  width: fit-content;
  align-items: center;
  padding: 0 16px;
  border-radius: 16px 0;
  --fp-comp-nav-link-icon-fill: var(--fp-color-blue-degree-95);
  cursor: pointer;

  &.full-width {
    width: 100%;
  }

  >.icon{
    display: flex;
  }

  >.title {
    white-space: nowrap;
  }

  &:hover,
  &:focus {
    background-color: var(--fp-color-blue-degree-97);
  }

  &:active {
    background-color: var(--fp-color-blue-degree-95);
  }

  &.active {
    cursor: default;
    --fp-comp-nav-link-icon-fill: var(--fp-color-white);
    background-color: var(--fp-color-turquoise-main);
  }
}