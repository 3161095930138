.fp-comp-input-text {

  --fp-comp-input-text-fg-color: var(--fp-color-white);
  --fp-comp-input-text-bg-color: var(--fp-color-blue-degree-95);
  --fp-comp-input-text-icon-fill: var(--fp-comp-input-text-fg-color);

  display: flex;
  align-items: center;
  border: 1px solid var(--fp-comp-input-text-fg-color);
  border-radius: 16px 0;
  height: 48px;
  padding-left: 24px;
  gap: 8px;
  margin-top: 8px;

  &.bg-white {
    --fp-comp-input-text-fg-color: var(--fp-color-blue-degree-95);
    --fp-comp-input-text-bg-color: var(--fp-color-white);
  }

  &.focus,
  &.filled {
    --fp-comp-input-text-icon-fill: var(--fp-color-turquoise-main);

    >.container>.label {
      top: -8px;
      left: -30px;
      padding: 0 8px;
      transition: top 200ms, left 500ms, padding 500ms;
      pointer-events: all;
      cursor: text;
    }
  }

  &:hover,
  &.filled,
  &.focus {
    border: 1px solid var(--fp-color-blue-main);
  }

  >.icon {
    width: 14px;
    min-width: 14px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  >.container {
    display: flex;
    align-items: center;
    position: relative;
    width: 100%;
    height: 100%;

    >.input {
      width: 100%;
      height: 100%;
      border-bottom-right-radius: 15px;
    }

    >.label {
      height: 16px;
      display: flex;
      align-items: center;
      position: absolute;
      left: 0;
      top: 15px;
      pointer-events: none;
      background-color: var(--fp-comp-input-text-bg-color);
      transition: top 500ms, left 200ms, padding 200ms;
    }
  }
}